import { AlertType } from '~/types';
import { HeaderSortOptions } from '~/types/misc';
import { dateDisplay } from '../dates/dateDisplay';
import { FEMUR_COMMENTS_COL, FEMUR_FLIGHT_DOWN_COL, FEMUR_FLIGHT_UP_COL, INSTALLED_ON_COL, INSTALLED_SERIAL_NUMBER_COL, IODINATION_DUE_DATE_COL, SERIAL_COL, SIZE_COL, STANDARD_COLS } from '../genericTableColumns';
import { alertableStatusDisplay } from '../helpers';
import { addAttributeDateDisplays, addBaseProperties, addFemurComments, addInstalledOnIcon, addIodinateDueDate, addLaunchReturn, addStatusDateDisplays } from '../mappers';
import { buildIconAsString } from '../ui/buildIconAsString';
import { CosmicIcons } from '../ui/CosmicIcons';
import { ACTIVITY_TABLE_HEADERS } from './activities';
export const HUT_ORU_TABLE_HEADERS = [
    ...STANDARD_COLS,
    SIZE_COL,
    INSTALLED_ON_COL,
    { ...INSTALLED_SERIAL_NUMBER_COL, text: 'Installed EMU Serial' },
    {
        text: 'WLVTA Replacement<br/>due',
        value: '_wltvaReplacementDue',
        sortable: true,
        noEscape: true,
        noWrap: true,
        headerSortType: HeaderSortOptions.DATE
    },
    {
        text: 'Wetted Status',
        value: '_wettedStatusIcon',
        sortable: true,
        noEscape: true,
        align: 'center'
    },
    IODINATION_DUE_DATE_COL
];
export const ACTIVITY_HUT_ORU_TABLE_HEADERS = [
    ...ACTIVITY_TABLE_HEADERS,
    {
        noWrap: true,
        sortable: true,
        headerSortType: HeaderSortOptions.DATE,
        width: '120px',
        text: 'HUT WLVTA R/R',
        value: '_hutWlvtaRemoveReplaceDueDate',
        align: 'left'
    },
    {
        text: 'Removed As-Built',
        value: '_removedAsBuilt',
        align: 'right'
    },
    {
        text: 'Removed Serial',
        value: '_removedSerial',
        align: 'right'
    },
    {
        text: 'Added As-Built',
        value: '_addedAsBuilt',
        align: 'right'
    },
    {
        text: 'Added Serial',
        value: '_addedSerial',
        align: 'right'
    }
];
export const FEMUR_HUT_ORU_TABLE_HEADERS = [
    SERIAL_COL,
    SIZE_COL,
    INSTALLED_ON_COL,
    { ...INSTALLED_SERIAL_NUMBER_COL, text: 'Installed EMU Serial' },
    {
        text: 'WLVTA R&R<br/>due',
        value: '_wltvaReplacementDue',
        sortable: true,
        noWrap: true,
        noEscape: true,
        headerSortType: HeaderSortOptions.DATE
    },
    {
        text: 'Wetted Status',
        value: '_wettedStatusIcon',
        sortable: true,
        noEscape: true,
        align: 'center'
    },
    IODINATION_DUE_DATE_COL,
    FEMUR_FLIGHT_UP_COL,
    FEMUR_FLIGHT_DOWN_COL,
    FEMUR_COMMENTS_COL
];
export const transformHutOru = (items) => {
    return items
        .map(addAttributeDateDisplays)
        .map(addBaseProperties)
        .map(addIodinateDueDate)
        .map(addLaunchReturn)
        .map(addStatusDateDisplays)
        .map(addFemurComments)
        .map(addInstalledOnIcon)
        .map((item) => {
        const status = item.status;
        let _wltvaReplacementDue = dateDisplay(status?.hutWlvtaRemoveReplaceDueDate?.value);
        _wltvaReplacementDue = alertableStatusDisplay(status?.hutWlvtaRemoveReplaceDueDate, _wltvaReplacementDue);
        const isWetted = Boolean(status?.lastPwrFillDate?.value);
        const isWettedStatusText = isWetted ? 'Wetted' : 'Dry';
        // alerts based on status
        const alerts = [
            status?.iodinateDueDate?.alertType,
            status?.hutWlvtaRemoveReplaceDueDate?.alertType
        ];
        const redAlert = alerts.find((alert) => alert === AlertType.ALERT);
        const orangeAlert = alerts.find((alert) => alert === AlertType.WARNING);
        const rowClass = redAlert ? 'red lighten-3' : orangeAlert ? 'orange lighten-3' : '';
        const _item = Object.assign({}, item, {
            rowClass,
            _wltvaReplacementDue,
            _wettedStatusText: isWettedStatusText,
            _wettedStatusIcon: buildIconAsString({
                iconClass: isWetted
                    ? `${CosmicIcons['WETTED']} text--darken-2`
                    : `${CosmicIcons['NON_WETTED']} text--darken-2`,
                color: isWetted ? 'success' : 'error',
                title: isWettedStatusText
            })
        });
        return _item;
    });
};
