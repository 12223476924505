import { AlertType } from '~/types';
import { dateDisplay } from '../dates/dateDisplay';
import { AUTOCYCLE_DUE_DATE_COL, CHARGER_SERIAL_COL, CHARGE_LOCATION_COL, CYCLE_COUNT_COL, DAYS_GREATER_THAN_FIFTY_PERCENT_SOC_COL, FEMUR_COMMENTS_COL, FEMUR_FLIGHT_DOWN_COL, FEMUR_FLIGHT_UP_COL, GENERIC_STATE_ICON_COL, LAST_DISCHARGE_AUTOCYCLE_COL, NINE_YEAR_EXPIRY_COL, SERIAL_COL, STANDARD_COLS, VOLTS_COL } from '../genericTableColumns';
import { alertableStatusDisplay } from '../helpers';
import { addAttributeDateDisplays, addAutocycleDueDate, addBaseProperties, addCycles, addDaysGreaterThanFiftyPercentSoc, addFemurComments, addGenericState, addLastDischargeAutocycle, addLaunchReturn, addStatusDateDisplays } from '../mappers';
import { ACTIVITY_TABLE_HEADERS } from './activities';
export const EMU_BATTERY_LLB_TABLE_HEADERS = [
    ...STANDARD_COLS,
    GENERIC_STATE_ICON_COL,
    DAYS_GREATER_THAN_FIFTY_PERCENT_SOC_COL,
    LAST_DISCHARGE_AUTOCYCLE_COL,
    AUTOCYCLE_DUE_DATE_COL,
    NINE_YEAR_EXPIRY_COL,
    CYCLE_COUNT_COL
];
export const FEMUR_EMU_BATTERY_LLB_TABLE_HEADERS = [
    SERIAL_COL,
    LAST_DISCHARGE_AUTOCYCLE_COL,
    NINE_YEAR_EXPIRY_COL,
    CYCLE_COUNT_COL,
    FEMUR_FLIGHT_UP_COL,
    FEMUR_FLIGHT_DOWN_COL,
    FEMUR_COMMENTS_COL
];
export const ACTIVITY_EMU_BATTERY_LLB_TABLE_HEADERS = [
    ...ACTIVITY_TABLE_HEADERS,
    VOLTS_COL,
    CHARGE_LOCATION_COL,
    CHARGER_SERIAL_COL
];
export const transformEmuBatteryLlb = (items) => {
    return items
        .map(addAttributeDateDisplays)
        .map(addAutocycleDueDate)
        .map(addBaseProperties)
        .map(addDaysGreaterThanFiftyPercentSoc)
        .map(addFemurComments)
        .map(addGenericState)
        .map(addLastDischargeAutocycle)
        .map(addLaunchReturn)
        .map(addStatusDateDisplays)
        .map(addCycles)
        .map((item) => {
        const status = item.status;
        let _nineYearExpiry = dateDisplay(status?.expirationDate?.value);
        _nineYearExpiry = alertableStatusDisplay(status?.expirationDate, _nineYearExpiry);
        // alerts based on status
        const alerts = [
            status?.batteryState?.alertType,
            status?.daysOver50PercentStateOfCharge?.alertType,
            status?.lastDischargeDate?.alertType,
            status?.expirationDate?.alertType,
            status?.autocycleDueDate?.alertType,
            status?.cycles?.alertType
        ];
        const redAlert = alerts.find((alert) => alert === AlertType.ALERT);
        const orangeAlert = alerts.find((alert) => alert === AlertType.WARNING);
        const rowClass = redAlert ? 'red lighten-3' : orangeAlert ? 'orange lighten-3' : '';
        const _item = Object.assign({}, item, {
            rowClass,
            _nineYearExpiry
        });
        return _item;
    });
};
