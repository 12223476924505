import { HardwareType, IeuLocation, MaintenanceCycle, MetoxItemStatus } from './schemas';
export var SizeType;
(function (SizeType) {
    SizeType["SMALL"] = "SMALL";
    SizeType["MEDIUM"] = "MEDIUM";
    SizeType["LARGE"] = "LARGE";
    SizeType["X_LARGE"] = "X_LARGE";
})(SizeType || (SizeType = {}));
export const SizeTypeDisplay = new Map([
    [SizeType.SMALL, 'Small'],
    [SizeType.MEDIUM, 'Medium'],
    [SizeType.LARGE, 'Large'],
    [SizeType.X_LARGE, 'X-Large']
]);
export const IeuLocationTypeDisplay = new Map([
    [IeuLocation.NOT_INSTALLED, 'Not Installed'],
    [IeuLocation.SCU_1, 'SCU 1'],
    [IeuLocation.SCU_2, 'SCU 2']
]);
export const MetoxItemStatusTypeDisplay = new Map([
    [MetoxItemStatus.EXPENDED, 'Expended'],
    [MetoxItemStatus.EXPENDED_25, '25% Expended'],
    [MetoxItemStatus.EXPENDED_50, '50% Expended'],
    [MetoxItemStatus.EXPENDED_75, '75% Expended'],
    [MetoxItemStatus.REGENERATED, 'Regenerated']
]);
export const MaintenanceCycleDisplay = new Map([
    [MaintenanceCycle.BATTERY_ACTIVITY_RESET, 'Battery Activity reset'],
    [MaintenanceCycle.EHIP_LIGHT_POWER_CYCLE, 'EHIP light power cycle'],
    [MaintenanceCycle.EMU_BATTERY_FIVE_YEAR_EXPIRATION, 'EMU battery 5y expiration'],
    [MaintenanceCycle.EMU_BIOCIDE_FILTER_USES, 'EMU biocide filter uses'],
    [MaintenanceCycle.EMU_DCM_MWC_FLOW_TEST, 'EMU DCM MWC flow test'],
    [MaintenanceCycle.EMU_FEEDWATER_SUPPLY_FILTER_VOLUME, 'EMU feedwater supply filter volume'],
    [MaintenanceCycle.EMU_FIRST_SUBLIMATOR_LEAK_TEST, 'EMU first sublimator leak test'],
    [MaintenanceCycle.EMU_FULL_WATER_DUMP, 'EMU full water dump'],
    [MaintenanceCycle.EMU_ITEM_146_CYCLE, 'EMU Item-146 cycle'],
    [MaintenanceCycle.EMU_PARTIAL_WATER_DUMP, 'EMU partial water dump'],
    [MaintenanceCycle.EMU_PIA_EXPIRATION, 'EMU PIA expiration'],
    [MaintenanceCycle.EMU_SCRUB_ASSEMBLY_USES, 'EMU scrub assembly uses'],
    [MaintenanceCycle.EMU_SOP_CHECK_OUT, 'EMU SOP CheckOut'],
    [MaintenanceCycle.EMU_SUBLIMATOR_LEAK_TEST, 'EMU sublimator leak test'],
    [MaintenanceCycle.EMU_TOTAL_EVAS, 'EMU total EVAs'],
    [MaintenanceCycle.EMU_WATER_SAMPLE, 'Annual Water Sample'],
    [MaintenanceCycle.TWENTY_FIVE_YEAR_EXPIRATION, '25y expiration'],
    [MaintenanceCycle.FPS_GREASE_CIRCULATE_INSTALLED, 'FPS grease circulate installed'],
    [MaintenanceCycle.FPS_GREASE_CIRCULATE_UNINSTALLED, 'FPS grease circulate uninstalled'],
    [MaintenanceCycle.HUT_WLVTA_REMOVE_REPLACE, 'HUT WLVTA R/R'],
    [MaintenanceCycle.LEGACY_FPU_RETURN, 'Legacy FPU return or flush before next use'],
    [MaintenanceCycle.LIB_AUTOCYCLE, 'LiB autocycle'],
    [MaintenanceCycle.LIB_CHARGER_CALIBRATION, 'LiB charger calibration'],
    [MaintenanceCycle.LIB_DAYS_OVER_50_PERCENT_STATE_OF_CHARGE, 'LiB days > 50% SOC'],
    [MaintenanceCycle.LITHIUM_BATTERY_EXPIRATION, 'LiB expiration'],
    [MaintenanceCycle.SAFER_LITHIUM_BATTERY_EXPIRATION, 'Safer LiB expiration'],
    [MaintenanceCycle.LLB_2_BATTERY_NINE_YEAR_EXPIRATION, '9yr expiration'],
    [MaintenanceCycle.LIB_DAYS_OVER_50_PERCENT_STATE_OF_CHARGE, 'LLB days > 50% SOC'],
    [MaintenanceCycle.LOOP_SCRUB_IODINATE, 'Loop scrub / iodinate'],
    [MaintenanceCycle.LOOP_SCRUB_IODINATE_LONG, 'Loop scrub / iodinate long'],
    [MaintenanceCycle.METOX_CYCLES_02_03, 'METOX cycles [02 / 03]'],
    [MaintenanceCycle.METOX_CYCLES_04, 'METOX cycles [04+]'],
    [MaintenanceCycle.METOX_CYCLES_SINCE_ORING_CHANGE, 'METOX cycles since O-Ring change'],
    [MaintenanceCycle.METOX_REGENERATION, 'METOX regeneration'],
    [MaintenanceCycle.METOX_SIMULATOR_CHANGE_OUT, 'METOX simulator change out'],
    [MaintenanceCycle.NONE, 'None'],
    [MaintenanceCycle.POST_EVA_SCRUB, 'Post EVA scrub'],
    [MaintenanceCycle.PRE_EVA_SCRUB, 'Pre EVA scrub'],
    [MaintenanceCycle.PRE_EVA_SAFER_CHECKOUT, 'Pre EVA SAFER Checkout'],
    [MaintenanceCycle.PRE_EVA_SAFER_GN2_CHECK, 'Pre EVA GN2 Check'],
    [MaintenanceCycle.SAFER_BATTERY_VOLTS, 'SAFER battery volts'],
    [MaintenanceCycle.SAFER_GN2, 'SAFER gn2'],
    [MaintenanceCycle.SAFER_ESTIMATED_AUTOCYCLE_RECHARGE, 'Safer Estimated Autocycle, Recharge, and Checkout'],
    [MaintenanceCycle.SAFER_PWR, 'SAFER PWR'],
    [MaintenanceCycle.SAFER_TM_CHECK_OUT, 'SAFER TM CheckOut'],
    [MaintenanceCycle.SCOF_PPRV_CYCLE, 'SCOF PPRV cycle'],
    [MaintenanceCycle.SCRUB, 'Scrub'],
    [MaintenanceCycle.TAK_DATA_APPROVAL, 'TAK data approval'],
    [MaintenanceCycle.TAK_DATA_COLLECTION, 'TAK data collection'],
    [MaintenanceCycle.UIA_BIOCIDE_FILTER_VOLUME, 'UIA biocide filter volume']
]);
export const HardwareParent = new Map([
    [HardwareType.HUT_ORU, HardwareType.EMU],
    [HardwareType.SAFER_LPGT_BATTERY, HardwareType.SAFER]
]);
export const HardwareTypeDisplay = new Map([
    [HardwareType.EHIP_LIGHT, 'EHIP Light'],
    [HardwareType.EMU, 'EMU'],
    [HardwareType.EMU_BATTERY, 'EMU Battery'],
    [HardwareType.EMU_BATTERY_LLB, 'EMU Battery LLB'],
    [HardwareType.EMU_BIOCIDE_FILTER, 'EMU Biocide Filter'],
    [HardwareType.EMU_FEEDWATER_SUPPLY_FILTER, 'EMU Feedwater Supply Filter'],
    [HardwareType.EMU_ION_FILTER, 'EMU Ion Filter'],
    [HardwareType.EMU_MICRON_FILTER, 'EMU Micron Filter'],
    [HardwareType.EMU_SCRUB_ASSEMBLY, 'EMU Scrub Assembly'],
    [HardwareType.EMU_WATER_PROCESSING_JUMPER, 'EMU Water Processing Jumper'],
    [HardwareType.FPS, 'FPS'],
    [HardwareType.HAB_HAP_E, 'HAB/HAP-E'],
    [HardwareType.HL_BATTERY, 'HL Battery'],
    [HardwareType.HUT_ORU, 'HUT ORU'],
    [HardwareType.HUT_ORU_SCRUBBER, 'HUT ORU Scrubber'],
    [HardwareType.IEU_SCU, 'IEU/SCU'],
    [HardwareType.LCVG, 'LCVG'],
    [HardwareType.LEGACY_FPU, 'Legacy FPU'],
    [HardwareType.LIB_CHARGER, 'LiB Charger'],
    [HardwareType.LPGT_BATTERY, 'LPGT Battery'],
    [HardwareType.LREBA, 'LREBA'],
    [HardwareType.METOX, 'METOX'],
    [HardwareType.METOX_SIMULATOR, 'METOX Simulator'],
    [HardwareType.PGT, 'PGT'],
    [HardwareType.PGT_BATTERY, 'PGT Battery'],
    [HardwareType.REBA, 'REBA'],
    [HardwareType.SAFER, 'SAFER'],
    [HardwareType.SAFER_LPGT_BATTERY, 'SAFER LPGT Battery'],
    [HardwareType.SAFETY_TETHER_55, 'Safety Tether 55ft'],
    [HardwareType.SAFETY_TETHER_85, 'Safety Tether 85ft'],
    [HardwareType.SCOF, 'SCOF'],
    [HardwareType.TETHER_EXTENSION_D_RING_EXTENDER, 'Tether Extension / D-Ring Extender'],
    [HardwareType.UIA, 'UIA'],
    [HardwareType.UIA_BIOCIDE_FILTER, 'UIA Biocide Filter'],
    [HardwareType.UIA, 'UIA'],
    [HardwareType.WAIST_TETHER, 'Waist Tether']
]);
export var FemurSpecificTypes;
(function (FemurSpecificTypes) {
    FemurSpecificTypes["EMU_IEU_SCU_UIA"] = "EMU_IEU_SCU_UIA";
})(FemurSpecificTypes || (FemurSpecificTypes = {}));
export const FemurTypeDisplay = new Map([
    ...HardwareTypeDisplay,
    [FemurSpecificTypes.EMU_IEU_SCU_UIA, 'EMU | IEU | SCU | UIA']
]);
// the available presets for the Annex 2 report
export var MaintenanceWindowPreset;
(function (MaintenanceWindowPreset) {
    MaintenanceWindowPreset["ALL_OVERDUE"] = "ALL_OVERDUE";
    MaintenanceWindowPreset["ALL_UPCOMING"] = "ALL";
    MaintenanceWindowPreset["DAYS_30"] = "30";
    MaintenanceWindowPreset["DAYS_60"] = "60";
    MaintenanceWindowPreset["DAYS_90"] = "90";
})(MaintenanceWindowPreset || (MaintenanceWindowPreset = {}));
export var HardwareLocation;
(function (HardwareLocation) {
    HardwareLocation["ON_ORBIT"] = "ON_ORBIT";
    HardwareLocation["ON_GROUND"] = "ON_GROUND";
})(HardwareLocation || (HardwareLocation = {}));
export const HardwareLocationDisplay = new Map([
    [HardwareLocation.ON_ORBIT, 'On Orbit'],
    [HardwareLocation.ON_GROUND, 'On Ground']
]);
