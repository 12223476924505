import { BatteryState, EvaReadinessDisplay, FullPartialStatusDisplay, ItemScuDisplay, MetoxItemStatus } from '~/types';
import { intervalDisplay } from '~/utils/dates/intervals';
import { ACTIVITY_DATE_COL, LAST_UPDATE_BY_COL, LAST_UPDATE_DATE_COL, SUBTYPE_COL } from '~/utils/genericTableColumns';
import { numberDisplay } from '~/utils/helpers/displays';
import { addActivitySubType, addComments, addDateDisplays, addLastUpdated } from '~/utils/mappers';
import { buildEvaReadinessIconAsString, buildIconAsString, buildNotOkIcon, buildOkIcon } from '~/utils/ui/buildIconAsString';
import { CosmicIcons } from '~/utils/ui/CosmicIcons';
import { dateDisplay } from '../dates/dateDisplay';
export const ACTIVITY_TABLE_HEADERS = [
    ACTIVITY_DATE_COL,
    SUBTYPE_COL,
    LAST_UPDATE_DATE_COL,
    LAST_UPDATE_BY_COL
];
const ActivityTransforms = {
    genericActivityTransform: function (items) {
        return items
            .map(addActivitySubType)
            .map(addComments)
            .map(addDateDisplays)
            .map(addLastUpdated)
            .map((item) => {
            item._hardwareCount = (item.itemInstances || []).length;
            return item;
        });
    },
    EHIP_LIGHT: function (item) {
        const attrs = item.attributes;
        // No known attributes
        return item;
    },
    EMU: function (item) {
        const attrs = item.attributes;
        item._fanOnGreaterThanTwoHoursIcon = attrs?.fanTimeOver2Hrs
            ? buildOkIcon('Fan logged > 2h On time')
            : buildNotOkIcon('Fan logged < 2h On time');
        item._sopPressure = attrs?.sopP || '-';
        item._waterDump = attrs?.fullPartial ? FullPartialStatusDisplay.get(attrs.fullPartial) : '-';
        item._emuTemp = attrs?.emuTemp ? numberDisplay(attrs.emuTemp, 2) : '-';
        item._ccaaTemp = attrs?.temp ? numberDisplay(attrs.temp, 2) : '-';
        item._emuTemp = attrs?.emuTemp ? numberDisplay(attrs.emuTemp, 2) : '-';
        item._scuSelection = attrs?.scu ? ItemScuDisplay.get(attrs.scu) : '-';
        item._sopRegulationPressure = attrs?.sopRegulationPressure || '-';
        return item;
    },
    EMU_BATTERY: function (item) {
        // EMU BATTERY Activity Types
        const attrs = item.attributes;
        item._isChargeIcon = attrs?.discharge ? '-' : buildOkIcon();
        item._isDischargeIcon = attrs?.discharge ? buildOkIcon() : '-';
        item._changeAmpHours = attrs?.chargeAmpHours ? `${attrs.chargeAmpHours} Ah` : '-';
        item._dischangeAmpHours = attrs?.dischargeAmpHours ? `${attrs.dischargeAmpHours} Ah` : '-';
        item._isDischargeTo16vIcon = attrs?.dischargeTo16V ? buildOkIcon() : buildNotOkIcon();
        item._location = attrs?.chargeLocation || '-';
        return item;
    },
    EMU_BATTERY_LLB: function (item) {
        // EMU Battery LLB Types
        const attrs = item.attributes;
        item._volts = attrs?.volts ? numberDisplay(attrs.volts, 2) : '-';
        item._chargeLocation = attrs?.chargeLocation || '-';
        item._chargerSerialNumber = attrs?.chargerSerialNumber || '-';
        return item;
    },
    EMU_BIOCIDE_FILTER: function (item) {
        // EMU Biocide Filter Types
        // EMU Biocide Filter Types
        const attrs = item.attributes;
        item._emuSerialNumber = attrs?.emuSerialNumber || '-';
        return item;
    },
    EMU_FEEDWATER_SUPPLY_FILTER: function (item) {
        // EMU Feedwater Supply Filter Types
        const attrs = item.attributes;
        item._water = attrs?.lbsWater ? numberDisplay(attrs.lbsWater, 2) : '-';
        return item;
    },
    EMU_ION_FILTER: function (item) {
        // EMU Ion Filter Types
        const attrs = item.attributes;
        item._emuSerialNumber = attrs?.emuSerialNumber || '-';
        return item;
    },
    EMU_MICRON_FILTER: function (item) {
        // EMU Micron Filter Types
        const attrs = item.attributes;
        item._emuSerialNumber = attrs?.emuSerialNumber || '-';
        return item;
    },
    EMU_SCRUB_ASSEMBLY: function (item) {
        // EMU Scrub Assembly Types
        const attrs = item.attributes;
        // No known attributes
        return item;
    },
    EMU_WATER_PROCESSING_JUMPER: function (item) {
        // EMU Water Processing Jumper Types
        const attrs = item.attributes;
        // No known attributes
        return item;
    },
    FPS: function (item) {
        // FPS Types
        const attrs = item.attributes;
        // No known attributes
        return item;
    },
    HAB_HAP_E: function (item) {
        const attrs = item.attributes;
        const status = attrs?.evaReadiness;
        item._status = status ? EvaReadinessDisplay.get(status) : '-';
        item._statusIcon = buildEvaReadinessIconAsString(status);
        const crewmemberAssigned = attrs?.crewmemberAssigned;
        item._crewmemberAssigned = crewmemberAssigned ?? '-';
        return item;
    },
    HL_BATTERY: function (item) {
        // HL Battery Types
        const attrs = item.attributes;
        item._finalChargeStateIcon = buildIconAsString({
            title: attrs?.isFinalStateCharged ? 'Final Charge State: CHARGED' : 'Final Charge State: NOT CHARGED',
            color: attrs?.isFinalStateCharged ? 'success' : 'error',
            iconClass: attrs?.isFinalStateCharged ? CosmicIcons[BatteryState.CHARGED] : CosmicIcons[BatteryState.NOT_CHARGED]
        });
        item._preChargeOcv = attrs?.prechargeOCV ? numberDisplay(attrs.prechargeOCV, 2) : '-';
        item._postChargeOcv = attrs?.postChargeOCV ? numberDisplay(attrs.postChargeOCV, 2) : '-';
        item._totalChargeTime = attrs?.totalChargeTime ? attrs.totalChargeTime : '-';
        item._chargeLocation = `${attrs?.chargeLocation || '-'} / ${attrs?.chargeLocationChannel || '-'}`;
        return item;
    },
    HUT_ORU: function (item) {
        // HUT ORU Types
        const attrs = item.attributes;
        item._removedAsBuilt = attrs?.removedAsBuiltNumber || '-';
        item._removedSerial = attrs?.removedSerialNumber || '-';
        item._addedAsBuilt = attrs?.addedAsBuiltNumber || '-';
        item._addedSerial = attrs?.addedSerialNumber || '-';
        item._hutWlvtaRemoveReplaceDueDate = attrs?.hutWlvtaRemoveReplaceDueDate
            ? dateDisplay(attrs.hutWlvtaRemoveReplaceDueDate)
            : '-';
        return item;
    },
    HUT_ORU_SCRUBBER: function (item) {
        // HUT ORU Scrubber Types
        const attrs = item.attributes;
        // No known attributes
        return item;
    },
    IEU_SCU: function (item) {
        // Ieu/Scu Types
        const attrs = item.attributes;
        // No known attributes
        return item;
    },
    LCVG: function (item) {
        // LCVG Types
        const attrs = item.attributes;
        // No known attributes
        return item;
    },
    LEGACY_FPU: function (item) {
        // Legacy FPU Types
        const attrs = item.attributes;
        // No known attributes
        return item;
    },
    LIB_CHARGER: function (item) {
        // LibCharger Types
        const attrs = item.attributes;
        // No known attributes
        return item;
    },
    LPGT_BATTERY: function (item) {
        // LpgtBattery Types
        const attrs = item.attributes;
        item._chargeLocation = `${attrs?.chargeLocation || ''} / ${attrs?.channel || '-'}`;
        item._chargerSerialNumber = attrs?.chargerSerialNumber || '-';
        item._volts = attrs?.volts ? numberDisplay(attrs.volts, 2) : '-';
        return item;
    },
    LREBA: function (item) {
        // Lreba Types
        const attrs = item.attributes;
        item._chargeLocation = `${attrs?.chargeLocation || ''} / ${attrs?.channel || '-'}`;
        item._chargerSerialNumber = attrs?.chargerSerialNumber || '-';
        item._volts = attrs?.volts ? numberDisplay(attrs.volts, 2) : '-';
        return item;
    },
    METOX: function (item) {
        // Metox Types
        const attrs = item.attributes;
        const iconOpts = {
            color: attrs?.metoxStatus === MetoxItemStatus.REGENERATED ? 'success' : 'error',
            title: attrs?.metoxStatus || '',
            iconClass: attrs?.metoxStatus === MetoxItemStatus.REGENERATED ? CosmicIcons['CHARGED'] : CosmicIcons['DISCHARGED']
        };
        item._status = attrs?.metoxStatus || '-';
        item._statusIcon = buildIconAsString(iconOpts);
        item._oRingChangeIcon = attrs?.oRingChange
            ? buildOkIcon('Activity contained O-Ring change')
            : buildNotOkIcon('Activity DID NOT contain an O-Ring change');
        return item;
    },
    METOX_SIMULATOR: function (item) {
        // MetoxSimulator Types
        const attrs = item.attributes;
        // No known attributes
        return item;
    },
    PGT: function (item) {
        // PGT Types
        const attrs = item.attributes;
        item._statusIcon = buildEvaReadinessIconAsString(attrs.evaReadiness);
        item._takSerial = attrs?.takSN || '-';
        item._role = attrs?.pgtRole || '-';
        return item;
    },
    PGT_BATTERY: function (item) {
        // PGT Battery Types
        const attrs = item.attributes;
        item._finalChargeStateIcon = buildIconAsString({
            title: attrs?.isFinalStateCharged ? 'Final Charge State: CHARGED' : 'Final Charge State: NOT CHARGED',
            color: attrs?.isFinalStateCharged ? 'success' : 'error',
            iconClass: attrs?.isFinalStateCharged ? CosmicIcons[BatteryState.CHARGED] : CosmicIcons[BatteryState.NOT_CHARGED]
        });
        item._preChargeOcv = attrs?.prechargeOCV ? numberDisplay(attrs.prechargeOCV, 2) : '-';
        item._postChargeOcv = attrs?.postChargeOCV ? numberDisplay(attrs.postChargeOCV, 2) : '-';
        item._totalChargeTime = attrs?.totalChargeTime ? attrs.totalChargeTime : '-';
        // item._chargeLocation = `${attrs?.chargeLocation || ''} / ${attrs?.chargeLocationChannel || '-'}`;
        return item;
    },
    REBA: function (item) {
        // REBA Types
        const attrs = item.attributes;
        // No known attributes ... perhaps remove?
        return item;
    },
    SAFER: function (item) {
        // SAFER Types
        const attrs = item.attributes;
        item._batteryVoltage = attrs?.batteryVolts ? numberDisplay(attrs.batteryVolts, 2) : '-';
        item._gn2 = attrs?.gn2Percent ? `${numberDisplay(attrs.gn2Percent, 0)}%` : '-';
        item._onTimeInterval =
            intervalDisplay({
                minutes: attrs?.onTimeMinutes,
                seconds: attrs?.onTimeSeconds
            }) || '-';
        item._pwr = attrs?.pwrPercent ? `${numberDisplay(attrs.pwrPercent, 0)}%` : '-';
        return item;
    },
    SAFER_LPGT_BATTERY: function (item) {
        // LpgtBattery Types
        const attrs = item.attributes;
        item._chargeLocation = `${attrs?.chargeLocation || ''} / ${attrs?.channel || '-'}`;
        item._chargerSerialNumber = attrs?.chargerSerialNumber || '-';
        item._volts = attrs?.volts ? numberDisplay(attrs.volts, 2) : '-';
        return item;
    },
    SAFETY_TETHER_55: function (item) {
        // 55 foot safety tether Types
        const attrs = item.attributes;
        const status = attrs?.evaReadiness;
        item._status = status ? EvaReadinessDisplay.get(status) : '-';
        item._statusIcon = buildEvaReadinessIconAsString(status);
        return item;
    },
    SAFETY_TETHER_85: function (item) {
        // 85 foot safety tether Types
        const attrs = item.attributes;
        const status = attrs?.evaReadiness;
        item._status = status ? EvaReadinessDisplay.get(status) : '-';
        item._statusIcon = buildEvaReadinessIconAsString(status);
        return item;
    },
    SCOF: function (item) {
        // scof Types
        const attrs = item.attributes;
        // No known attributes
        return item;
    },
    TETHER_EXTENSION_D_RING_EXTENDER: function (item) {
        // tether extension D-ring Types
        const attrs = item.attributes;
        const status = attrs?.evaReadiness;
        item._status = status ? EvaReadinessDisplay.get(status) : '-';
        item._statusIcon = buildEvaReadinessIconAsString(status);
        return item;
    },
    UIA: function (item) {
        // uia Types
        const attrs = item.attributes;
        // No known attributes
        return item;
    },
    UIA_BIOCIDE_FILTER: function (item) {
        // uia biocide filter Types
        const attrs = item.attributes;
        const volume = attrs?.lbsWater || 0;
        const rounded = (Math.round(volume * 100) / 100).toFixed(2);
        item._volume = `${rounded}`;
        return item;
    },
    WAIST_TETHER: function (item) {
        // waist tether Types
        const attrs = item.attributes;
        const status = attrs?.evaReadiness;
        item._status = status ? EvaReadinessDisplay.get(status) : '-';
        item._statusIcon = buildEvaReadinessIconAsString(status);
        return item;
    }
};
export const transformActivities = (items, hardwareType) => {
    if (hardwareType && ActivityTransforms[hardwareType]) {
        const _hardwareTypeTransform = ActivityTransforms[hardwareType];
        return ActivityTransforms.genericActivityTransform(items).map(_hardwareTypeTransform);
    }
    return ActivityTransforms.genericActivityTransform(items);
};
