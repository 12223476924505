import { CommentTypeDisplay } from '~/types';
import { HeaderSortOptions } from '~/types/misc';
import { CommentType } from '~/types/schemas';
export const ACTIVITY_DATE_COL = {
    text: 'Activity<br/>Date',
    noWrap: true,
    value: '_activityDate',
    sortable: true,
    headerSortType: HeaderSortOptions.DATE,
    width: '120px'
};
export const AMOUNT_WATER_COL = {
    align: 'right',
    headerSortType: HeaderSortOptions.NUMBER,
    sortable: true,
    text: 'Lbs of H<sub>2</sub>O',
    value: '_volume',
    noEscape: true
};
export const AS_BUILT_COL = {
    text: 'As-Built',
    value: '_asBuiltNumber',
    sortable: true
};
export const AUTOCYCLE_DUE_DATE_COL = {
    text: 'Autocycle due',
    value: '_autocycleDueDate',
    noWrap: true,
    sortable: true,
    noEscape: true,
    headerSortType: HeaderSortOptions.DATE
};
export const BARCODE_COL = {
    text: 'Barcode',
    value: '_barcode',
    sortable: true
};
export const BATTERY_VOLTAGE_COL = {
    text: 'Battery<br/>Voltage',
    value: '_batteryVoltage',
    sortable: true,
    noEscape: true,
    align: 'right',
    headerSortType: HeaderSortOptions.NUMBER
};
export const CHARGE_LOCATION_COL = {
    text: 'Charge<br/>Location',
    value: '_chargeLocation',
    sortable: true
};
export const CHARGER_SERIAL_COL = {
    text: 'Charger Serial',
    value: '_chargerSerialNumber',
    sortable: true
};
export const COMMENTS_COL = {
    text: 'Comments',
    value: '_comments',
    sortable: true,
    noEscape: true,
    noWrap: true
};
export const COMPLETED_EVAS_COL = {
    text: 'Completed<br/>EVAs<br/><small>(max 25)</small>',
    value: '_completedEvas',
    sortable: true,
    noEscape: true,
    headerSortType: HeaderSortOptions.NUMBER,
    align: 'right'
};
export const CREWMEMBER_ASSIGNED_COL = {
    text: 'Crewmember<br/>assigned',
    value: '_crewmemberAssigned',
    sortable: true
};
export const CYCLE_COUNT_COL = {
    text: 'Cycle<br/>Count',
    value: '_cycles',
    sortable: true,
    align: 'right',
    headerSortType: HeaderSortOptions.NUMBER,
    noEscape: true
};
export const DAYS_GREATER_THAN_FIFTY_PERCENT_SOC_COL = {
    text: 'Days > 50% SOC',
    value: '_daysGreaterThanFiftyPercentSoc',
    sortable: true,
    align: 'right',
    noEscape: true
};
export const DAYS_IN_SAFER_BATTERY_ADAPTER = {
    text: 'Days In SAFER adapter',
    value: '_daysInSaferBatteryAdapter',
    sortable: true,
    align: 'right',
    noEscape: true
};
export const EMU_SERIAL_COL = {
    text: 'EMU Serial',
    value: '_emuSerialNumber',
    sortable: true
};
export const END_DATE_COL = {
    text: 'End',
    value: '_endDate',
    sortable: true,
    noWrap: true,
    headerSortType: HeaderSortOptions.DATE
};
export const EVA_READINESS_COL = {
    text: 'EVA Readiness',
    value: '_evaReadinessIcon',
    sortable: true,
    noWrap: true,
    noEscape: true,
    align: 'center'
};
export const FEMUR_COMMENTS_COL = {
    text: `${CommentTypeDisplay.get(CommentType.FEMU_R_003)} Comments`,
    value: '_comments',
    noEscape: true
};
export const FEMUR_POWER_CYCLE_DUE_COL = {
    text: `Power Cycle due`,
    value: '_powerCycleDueDate',
    noEscape: true,
    sortable: true,
    headerSortType: HeaderSortOptions.DATE
};
export const FEMUR_LAST_ACTUAL_POWER_CYCLE_COL = {
    text: `Power Cycle<br/>last actual`,
    value: '_powerCycleLastActualDate',
    noEscape: true,
    sortable: true,
    headerSortType: HeaderSortOptions.DATE
};
export const FEMUR_LAST_ACTUAL_MAINT_COL = {
    text: 'Last actual',
    value: '_lastActualMaintDate',
    sortable: true,
    noWrap: true,
    headerSortType: HeaderSortOptions.DATE
};
export const FEMUR_FLIGHT_DOWN_COL = {
    text: 'Flight down',
    value: '_returnEventTitle',
    noEscape: true,
    noWrap: true
};
export const FEMUR_FLIGHT_UP_COL = {
    text: 'Flight up',
    value: '_launchEventTitle',
    noEscape: true,
    noWrap: true
};
export const FINAL_CHARGE_STATE_COL = {
    text: 'Final Charge<br/>state',
    value: '_finalChargeStateIcon',
    sortable: true,
    noEscape: true,
    align: 'center'
};
export const IODINATION_DUE_DATE_COL = {
    text: 'Iodination due',
    value: '_iodinateDueDate',
    noWrap: true,
    sortable: true,
    noEscape: true,
    headerSortType: HeaderSortOptions.DATE
};
export const INSTALLED_COL = {
    text: 'Installed?',
    value: '_isInstalledIcon',
    sortable: true,
    align: 'center',
    noEscape: true
};
export const INSTALLED_ON_COL = {
    text: 'Installed/Stowed',
    value: '_isInstalledIcon',
    sortable: true,
    noEscape: true,
    align: 'center'
};
export const INSTALLED_SERIAL_NUMBER_COL = {
    text: 'Installed On Serial',
    value: '_installedSerialNumber',
    sortable: true
};
export const ITEM_146_EXPIRATION_COL = {
    text: 'Item 146<br/>expiration',
    value: '_item146ExpirationDate',
    sortable: true,
    headerSortType: HeaderSortOptions.DATE,
    noWrap: true,
    noEscape: true
};
export const FILTER_CHECKOUT_DUE_COL = {
    headerSortType: HeaderSortOptions.DATE,
    noEscape: true,
    sortable: true,
    noWrap: true,
    text: 'Filter CheckOut due<br/><small>(every 1,095d)</small>',
    value: '_filterCheckOutDueDate'
};
export const FIVE_YEAR_EXPIRY_COL = {
    text: '5 yr expiry',
    value: '_fiveYearExpiry',
    sortable: true,
    noWrap: true,
    noEscape: true,
    headerSortType: HeaderSortOptions.DATE
};
export const FIVE_YEAR_MAINT_COL = {
    text: '5 yr Maintenance due',
    value: '_fiveYearMaintenanceDueDate',
    sortable: true,
    noEscape: true
};
export const TWENTY_FIVE_YEAR_EXPIRY_COL = {
    text: '25 yr Life<br/>expiry',
    value: '_twentyFiveYearLifeExpirationDate',
    sortable: true,
    noWrap: true,
    noEscape: true,
    headerSortType: HeaderSortOptions.DATE
};
export const GENERIC_STATUS_COL = {
    text: 'Status',
    value: '_status',
    sortable: true
};
export const GENERIC_STATUS_ICON_COL = {
    text: 'Status',
    value: '_statusIcon',
    sortable: true,
    align: 'center',
    noEscape: true
};
export const GENERIC_STATE_COL = {
    text: 'State',
    value: '_state',
    sortable: true
};
export const GENERIC_STATE_ICON_COL = {
    text: 'State',
    value: '_stateIcon',
    sortable: true,
    align: 'center',
    noEscape: true
};
export const IS_ACTIVE_COL = {
    text: 'Is Active?',
    value: '_isActive',
    sortable: true,
    align: 'center',
    noEscape: true
};
export const LAST_DISCHARGE_AUTOCYCLE_COL = {
    text: 'Last Discharge /<br/>Last Autocycle',
    value: '_lastDischargeAutocycle',
    sortable: true,
    noEscape: true,
    noWrap: true,
    headerSortType: HeaderSortOptions.DATE
};
export const LAST_UPDATE_BY_COL = {
    text: 'Last updated<br/>by',
    noWrap: true,
    headerSortType: HeaderSortOptions.TEXT,
    value: '_lastUpdatedBy',
    sortable: true
};
export const LAST_UPDATE_DATE_COL = {
    text: 'Last updated',
    noWrap: true,
    value: '_lastUpdateDate',
    sortable: true,
    headerSortType: HeaderSortOptions.DATE
};
export const LOCATION_ICON_COL = {
    text: 'Current<br/>Location',
    value: '_locationIcon',
    sortable: true,
    noEscape: true,
    align: 'center'
};
export const LOCATION_COL = {
    text: 'Current<br/>Location',
    value: '_location',
    sortable: true
};
export const NAME_COL = {
    text: 'Name',
    value: '_name',
    sortable: true
};
export const NEXT_ACTIVITY_RESET_DUE_DATE_COL = {
    text: 'Next Activity reset due',
    value: '_nextActivityResetDueDate',
    sortable: true,
    noEscape: true,
    noWrap: true,
    headerSortType: HeaderSortOptions.DATE
};
export const NEXT_PLANNED_EVA_COL = {
    text: 'Next planned<br/>EVA',
    value: '_nextPlannedEva',
    sortable: true,
    headerSortType: HeaderSortOptions.DATE,
    noWrap: true
};
export const PIA_DATE_COL = {
    text: 'PIA',
    value: '_piaDate',
    sortable: true,
    noWrap: true,
    noEscape: true,
    headerSortType: HeaderSortOptions.DATE
};
export const POSTCHARGE_OCV_COL = {
    text: 'Post-charge OCV',
    value: '_postChargeOcv',
    sortable: true,
    align: 'right',
    headerSortType: HeaderSortOptions.NUMBER
};
/**
 * NLT => No Later Than
 */
export const POST_EVA_NLT_COL = {
    headerSortType: HeaderSortOptions.DATE,
    noWrap: true,
    sortable: true,
    text: 'Post EVA<br/>NLT<small><br/>(within 1w / 90d)</small>',
    value: '_postEVANLTDate',
    noEscape: true
};
export const POST_SCRUB_IODINATE_LAST_ACTUAL_COL = {
    headerSortType: HeaderSortOptions.DATE,
    noWrap: true,
    sortable: true,
    text: 'Post Scrub & Iodinate<br/>Last Actual<br/><small>(within 1w / 90d)</small>',
    value: '_lastActualPostScrubDate',
    noEscape: true
};
export const POST_SCRUB_IODINATE_DUE_COL = {
    headerSortType: HeaderSortOptions.DATE,
    noWrap: true,
    sortable: true,
    text: 'Post Scrub & Iodinate due<br/><small>(within 1w / 90d)</small>',
    value: '_iodinateDueDate',
    noEscape: true
};
export const PRECHARGE_OCV_COL = {
    text: 'Pre-Charge OCV',
    value: '_preChargeOcv',
    sortable: true,
    align: 'right',
    headerSortType: HeaderSortOptions.NUMBER
};
/**
 * NET => No Earlier Than
 */
export const PRE_EVA_SCRUB_NET_COL = {
    headerSortType: HeaderSortOptions.DATE,
    noWrap: true,
    sortable: true,
    text: 'Pre EVA Scrub<br/>NET<br/><small>(2w prior to EVA)</small>',
    value: '_preEvaScrubNetDate',
    noEscape: true
};
export const SERIAL_COL = {
    text: 'Serial',
    value: '_serial',
    sortable: true,
    noWrap: true
};
export const SEVEN_YEAR_EXPIRY_COL = {
    text: '7 yr expiry',
    value: '_sevenYearExpiry',
    sortable: true,
    noWrap: true,
    noEscape: true,
    headerSortType: HeaderSortOptions.DATE
};
export const NINE_YEAR_EXPIRY_COL = {
    text: '9 yr expiry',
    value: '_nineYearExpiry',
    sortable: true,
    noWrap: true,
    noEscape: true,
    headerSortType: HeaderSortOptions.DATE
};
export const SIZE_COL = {
    text: 'Size',
    value: '_size',
    sortable: true,
    noWrap: true,
    noEscape: true,
    headerSortType: HeaderSortOptions.TEXT
};
export const START_DATE_COL = {
    text: 'Start',
    value: '_startDate',
    sortable: true,
    noWrap: true,
    headerSortType: HeaderSortOptions.DATE
};
export const SUBTYPE_COL = {
    text: 'Type',
    noWrap: true,
    value: '_subType',
    sortable: true,
    noEscape: true
};
export const TAPE_INSTALL_DATE_COL = {
    text: 'Tape install',
    value: '_tapeInstallDate',
    sortable: true,
    noWrap: true,
    headerSortType: HeaderSortOptions.DATE
};
export const TOTAL_CHARGE_TIME_COL = {
    text: 'Total Charge Time',
    value: '_totalChargeTime',
    sortable: true,
    align: 'right'
};
export const USER_ROLE_CODE = {
    text: 'Group Code',
    value: '_groupCode',
    sortable: true
};
export const USER_ROLE_NAME = {
    text: 'Group Name',
    value: '_groupName',
    sortable: true
};
export const USES_AVAILABLE_COL = {
    text: 'Uses Available',
    value: '_usesAvailable',
    sortable: true,
    headerSortType: HeaderSortOptions.NUMBER,
    align: 'right',
    noEscape: true
};
export const VOLTS_COL = {
    text: 'Volts',
    value: '_volts',
    sortable: true,
    noEscape: true,
    align: 'right',
    headerSortType: HeaderSortOptions.NUMBER
};
export const WATER_CIRCULATION_DUE_DATE_COL = {
    headerSortType: HeaderSortOptions.DATE,
    noEscape: true,
    sortable: true,
    text: 'H<sub>2</sub>O Circulation due<br/><small>(max 14)</small>',
    value: '_waterCirculationDueDate'
};
export const WATER_SAMPLE_DUE_DATE_COL = {
    headerSortType: HeaderSortOptions.DATE,
    noEscape: true,
    sortable: true,
    text: 'Water Sample due<br/><small>(every 365 days)</small>',
    value: '_waterSampleDueDate'
};
export const STANDARD_COLS = [SERIAL_COL, BARCODE_COL, LOCATION_ICON_COL, EVA_READINESS_COL];
export const STANDARD_PLUS_GEN_STATUS = [...STANDARD_COLS, GENERIC_STATUS_ICON_COL];
