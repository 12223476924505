import { AlertType } from '~/types';
import { HeaderSortOptions } from '~/types/misc';
import { alertableStatusDisplay, numberDisplay } from '..';
import { FEMUR_COMMENTS_COL, FEMUR_FLIGHT_DOWN_COL, FEMUR_FLIGHT_UP_COL, SERIAL_COL, STANDARD_COLS, USES_AVAILABLE_COL } from '../genericTableColumns';
import { addBaseProperties, addFemurComments, addLaunchReturn, addUsesAvailable } from '../mappers';
import { ACTIVITY_TABLE_HEADERS } from './activities';
export const EMU_SCRUB_ASSEMBLY_TABLE_HEADERS = [...STANDARD_COLS, USES_AVAILABLE_COL];
export const FEMUR_EMU_SCRUB_ASSEMBLY_TABLE_HEADERS = [
    SERIAL_COL,
    {
        text: 'Total Uses<br/><small>(max 15)</small>',
        value: '_totalUses',
        sortable: true,
        align: 'right',
        headerSortType: HeaderSortOptions.NUMBER,
        noEscape: true
    },
    FEMUR_FLIGHT_UP_COL,
    FEMUR_FLIGHT_DOWN_COL,
    FEMUR_COMMENTS_COL
];
export const ACTIVITY_EMU_SCRUB_ASSEMBLY_TABLE_HEADERS = [...ACTIVITY_TABLE_HEADERS];
export const transformEmuScrubAssembly = (items) => {
    return items
        .map(addBaseProperties)
        .map(addUsesAvailable)
        .map(addLaunchReturn)
        .map(addFemurComments)
        .map((item) => {
        const status = item.status;
        let _totalUses = numberDisplay(status?.useCount?.value);
        _totalUses = alertableStatusDisplay(status?.useCount, _totalUses);
        // adding row class based on useCount
        const rowClass = item.status?.useCount?.alertType === AlertType.ALERT
            ? 'red lighten-3'
            : item.status?.useCount?.alertType === AlertType.WARNING
                ? 'orange lighten-3'
                : '';
        const _item = Object.assign({}, item, {
            rowClass,
            _totalUses
        });
        return _item;
    });
};
export const transformEmuScrubAssemblyStatusPropertiesForAhd = (keys) => {
    const statusKeys = keys;
    return statusKeys.map((key) => {
        // Use "usesAvailable" instead of "useCount" for EmuScrubAssembly
        return key === 'useCount' ? 'usesAvailable' : key;
    });
};
