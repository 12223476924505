var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { HardwareType, Maybe } from '~/types';
import { getFormFields } from '~/utils/FormBuilder';
let HardwareFormMixin = class HardwareFormMixin extends Vue {
    isFetching = false;
    isSaving = false;
    attributeFormPartials = [];
    formData = {
        asBuiltNumber: null,
        attributes: {},
        barcode: null,
        drawingNumber: null,
        femuRCommentText: null,
        launchEventId: null,
        returnEventId: null,
        generalCommentText: null,
        isArchived: false,
        serialNumber: null,
        size: null,
        parentItemInstanceId: null
    };
    subType;
    get hasAttributeFields() {
        return Boolean(this.attributeFormPartials.length);
    }
    /**
     * Checks to see if the formData contains the minimum
     */
    get isBaseItemInstanceValid() {
        return Boolean(this.formData.drawingNumber && this.formData.asBuiltNumber && this.formData.serialNumber);
    }
    get isHutOruValid() {
        if (this.subType !== HardwareType.HUT_ORU) {
            return true;
        }
        return Boolean(this.formData.attributes.initialHutWlvtaRemoveReplaceDueDate);
    }
    // called from ui
    onFormDataChange(val) {
        this.formData = val;
    }
    async setAttributeFormPartials(hardwareType) {
        if (hardwareType) {
            this.attributeFormPartials = await getFormFields(hardwareType);
        }
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof HardwareType !== "undefined" && HardwareType) === "function" ? _a : Object)
], HardwareFormMixin.prototype, "subType", void 0);
__decorate([
    Watch('subType', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object]),
    __metadata("design:returntype", Promise)
], HardwareFormMixin.prototype, "setAttributeFormPartials", null);
HardwareFormMixin = __decorate([
    Component
], HardwareFormMixin);
export { HardwareFormMixin };
