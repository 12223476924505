import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let AppFormWrapper = class AppFormWrapper extends Vue {
    form = null;
    color;
    colorModifier;
    size;
    top;
    right;
    left;
    bottom;
    noBorder;
    validateOnLoad;
    required;
    get computedTop() {
        return this.size || this.top;
    }
    get computedRight() {
        return this.size || this.right;
    }
    get computedLeft() {
        return this.size || this.left;
    }
    get computedBottom() {
        return this.size || this.bottom;
    }
    get computedClasses() {
        if (this.noBorder) {
            return [];
        }
        const classes = [this.color];
        classes.push(this.colorModifier);
        classes.push(`pt-${this.computedTop}`);
        classes.push(`pr-${this.computedRight}`);
        classes.push(`pl-${this.computedLeft}`);
        classes.push(`pb-${this.computedBottom}`);
        return classes;
    }
    mounted() {
        this.form = this.$refs['form'];
        if (this.form && this.validateOnLoad) {
            setTimeout(() => {
                this.form.validate();
            }, 1000);
        }
    }
};
__decorate([
    Prop({
        default: 'grey'
    }),
    __metadata("design:type", String)
], AppFormWrapper.prototype, "color", void 0);
__decorate([
    Prop({
        default: 'lighten-2'
    }),
    __metadata("design:type", String)
], AppFormWrapper.prototype, "colorModifier", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppFormWrapper.prototype, "size", void 0);
__decorate([
    Prop({
        default: '2'
    }),
    __metadata("design:type", String)
], AppFormWrapper.prototype, "top", void 0);
__decorate([
    Prop({
        default: '2'
    }),
    __metadata("design:type", String)
], AppFormWrapper.prototype, "right", void 0);
__decorate([
    Prop({
        default: '2'
    }),
    __metadata("design:type", String)
], AppFormWrapper.prototype, "left", void 0);
__decorate([
    Prop({
        default: '2'
    }),
    __metadata("design:type", String)
], AppFormWrapper.prototype, "bottom", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppFormWrapper.prototype, "noBorder", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppFormWrapper.prototype, "validateOnLoad", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppFormWrapper.prototype, "required", void 0);
AppFormWrapper = __decorate([
    Component
], AppFormWrapper);
export default AppFormWrapper;
